import * as React from 'react'
import { graphql } from 'gatsby'
import SEO from '@/components/seo'
import { Layout } from '@/layouts'
import BlogCard from '@/components/BlogCard'

interface Props {
  data: any
}
const Top: React.FC<Props> = (props): JSX.Element => {
  const markdowns = props.data.allMarkdownRemark || {}

  return (
    <Layout>
      <SEO
        title='トップページ'
        description='taikishiinoのホームページのトップページです。'
      />
      <>
        {markdowns.edges.map(({ node }: any) => (
          <div key={node.id}>
            <BlogCard node={node} />
          </div>
        ))}
      </>
    </Layout>
  )
}
export default Top

export const query = graphql`
  query IndexQuery {
    allMarkdownRemark(
      filter: { fields: { collection: { eq: "blog" } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            date(formatString: "YYYY/MM/DD")
            title
            discription
            tags
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`
