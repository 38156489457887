import * as React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import Tag from '@/components/atoms/Tag'

interface Props {
  node: any
}
const BlogListItem: React.FC<Props> = (props): JSX.Element => {
  const blogTags =
    props.node.frontmatter.tags &&
    props.node.frontmatter.tags.map((tag: any, i: number) => (
      <Tag value={tag} key={i} />
    ))

  return (
    <StyledComponent>
      <Link to={props.node.fields.slug} className='blog-card-body'>
        <h2>{props.node.frontmatter.title}</h2>
        <div className='discription'>{props.node.frontmatter.discription}</div>
      </Link>
      <div className='blog-card-footer'>
        <div className='blog-card-footer__tags'>{blogTags}</div>
        <i>{props.node.frontmatter.date}</i>
      </div>
    </StyledComponent>
  )
}
export default BlogListItem

const StyledComponent = styled.div`
  border-bottom: 1px solid #e6e6e6;
  padding: 40px 0;

  .blog-card-body {
    font-family: 'Noto Sans Japanese', '游ゴシック体', 'Yu Gothic', YuGothic,
      'ヒラギノ角ゴシック Pro', 'Hiragino Kaku Gothic Pro', 'メイリオ', Meiryo,
      Osaka, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
    font-weight: 300;
    text-decoration: none;

    h2 {
      font-weight: 800;
      line-height: 1.5;
      color: #000;
      margin-bottom: 4px;
      font-size: 20px;
    }
    .discription {
      color: #787c7b;
      white-space: pre-wrap;
      font-size: 14px;
      line-height: 16px;
    }
  }

  .blog-card-footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 12px;

    i {
      font-size: 14px;
      font-style: oblique;
      color: #787c7b;
    }
  }

  @media screen and (max-width: 1024px) {
    padding: 32px 24px;

    .blog-card-body {
      h2 {
        font-size: 18px;
      }
      .discription {
        font-size: 12px;
      }
    }
  }
  @media screen and (max-width: 768px) {
    padding: 24px 16px;

    .blog-card-body {
      h2 {
        font-size: 16px;
      }
      .discription {
        font-size: 11px;
      }
    }
    .blog-card-footer {
      i {
        font-size: 11px;
      }
    }
  }
  @media screen and (max-width: 480px) {
  }
`
